import React, {useState, useRef, useEffect} from 'react';

import {Product, Funding, ProductCategories} from '@store/content/types';
import ProductsList from '@components/configurator/products/ProductsList';
import { ConfiguratorDependent } from '@store/types';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';
import MetaPage from '@components/common/MetaPage';
import ConfiguratorInfoMessage from '@components/common/ConfiguratorInfoMessage';
import { LocationDependent } from '@content/types/general';
import Container from '@components/layout/Container';
import {groupProducts} from "../../../utils/products";
import ProductCategoryTitle from "@components/common/ProductCategoryTitle";
import Button from "@components/common/Button";
import styled from "@emotion/styled";
import {respondFrom, breakpoints, css, colors} from '@styles';

const ButtonWrapper = styled.div`
  display: none;

  ${respondFrom(
    breakpoints.md,
    css`
      display: block;
      margin: -24px 0 12px 2px;
    `
  )}
  
  &.expanded {
    .button-text {
      &:after {
        transform: rotate(180deg) translateY(50%);
      }
    }
  }

  button {
    width: 100%;
    background-color: ${colors.ui.whisper} !important;
    border: solid 1px ${colors.gray} !important;
    font-size: 14px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    text-transform: none !important;
    
    span::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid ${colors.black};
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -15px;
    }
  }
`;

interface ProductsProps extends ConfiguratorDependent, LocationDependent {
  products: Product[];
  pageSettings: ConfiguratorSettings;
  langcode: string;
  allFundings: Funding[];
  productCategories: ProductCategories[];
  sliderSettings?: object;
}

export const ConfiguratorTerminals = (props: ProductsProps) => {
  const { products, productCategories, langcode, pageSettings } = props;
  const [hideItemsBottom, setHideItemsBottom] = useState<{ [key: string]: boolean }>({});
  const [slickTrackWidths, setSlickTrackWidths] = useState<number[]>([]);
  const [slickTrackWidthDefault, setSlickTrackWidthDefault] = useState<number>(0);
  const groupedProducts = groupProducts(products, productCategories, langcode);
  const toggleHideItemsBottom = (tid: string) => {
    setHideItemsBottom((prevState) => ({
      ...prevState,
      [tid]: !prevState[tid],
    }));
  };
  const sliderRefs = useRef<(HTMLDivElement | null)[]>([]);
  const sliderRefDefault = useRef<(HTMLDivElement | null)>(null);
  const offsetFix = 20;

  const updateSlickTrackWidths = () => {
    const widths = sliderRefs.current.map((ref) => {
      if (ref) {
        const slickTrack = ref.querySelector<HTMLElement>('.slick-track');
        return slickTrack ? slickTrack.offsetWidth - offsetFix : 0;
      }
      return 0;
    });
    setSlickTrackWidths(widths);
  };

  const updateSlickTrackWidthDefault = () => {
    if (sliderRefDefault.current) {
      const slickTrack = sliderRefDefault.current.querySelector<HTMLElement>('.slick-track');
      setSlickTrackWidthDefault(slickTrack ? slickTrack.offsetWidth - offsetFix : 0);
    }
  };

  const getExpandButtonText = (tid: string, langcode: string): string | undefined => {
    const item = productCategories[langcode].find(entry => entry.langcode === langcode && entry.tid === tid);
    return item ? item.expand_button_text : undefined;
  }

  const getHideButtonText = (tid: string, langcode: string): string | undefined => {
    const item = productCategories[langcode].find(entry => entry.langcode === langcode && entry.tid === tid);
    return item ? item.hide_button_text : undefined;
  }

  useEffect(() => {
    updateSlickTrackWidths();
    updateSlickTrackWidthDefault();
  }, []);

  return (
    <Container mode={{ breakpoints: "md", width: "narrow" }}>
      <MetaPage title="Sklep" />

      {props.pageSettings.field_enable_info_text && (
        <ConfiguratorInfoMessage
          message={
            props.pageSettings.field_info_text ? props.pageSettings.field_info_text.value : ''
          }
        />
      )}

      {productCategories[langcode].map((category, index) => {
        const { tid, name, tooltip } = category;
        return (
          <React.Fragment key={`fragment-${tid}-${langcode}`}>
            <ProductCategoryTitle
              tid={tid}
              langcode={langcode}
              name={name}
              tooltip={tooltip}
            />

            <div ref={(el) => (sliderRefs.current[index] = el)}>
              <ProductsList
                key={`products-${tid}-${langcode}`}
                {...props}
                products={groupedProducts[tid]}
                showItemsBottom={hideItemsBottom[tid] || false}
              />
            </div>

            {groupedProducts[tid][langcode].length > 1 && (
              <ButtonWrapper
                className={hideItemsBottom[tid] ? 'expanded' : ''}
                style={slickTrackWidths[index] !== 0 ? { maxWidth: slickTrackWidths[index] } : {}}
              >
                <Button
                  color="outlined"
                  size="smaller"
                  onClick={() => toggleHideItemsBottom(tid)}
                >
                  {!hideItemsBottom[tid] && getExpandButtonText(tid, langcode)}
                  {hideItemsBottom[tid] && getHideButtonText(tid, langcode)}
                </Button>
              </ButtonWrapper>
            )}
          </React.Fragment>
        )
      })}

      {groupedProducts.default && (
        <div ref={sliderRefDefault} style={{ marginTop: '32px'}}>
          <ProductsList
            {...props}
            products={groupedProducts.default}
            showItemsBottom={hideItemsBottom['default'] || false}
          />
          {groupedProducts.default[langcode].length > 1 && (
            <ButtonWrapper style={slickTrackWidthDefault !== 0 ? { maxWidth: slickTrackWidthDefault } : {}}>
              <Button
                color="outlined"
                size="smaller"
                onClick={() => toggleHideItemsBottom('default')}
              >
                {!hideItemsBottom['default']&& pageSettings.field_proste_pola[188] || ''}
                {hideItemsBottom['default'] && pageSettings.field_proste_pola[189] || ''}
              </Button>
            </ButtonWrapper>
          )}
        </div>
      )}
    </Container>
  );
};

export default ConfiguratorTerminals;
